.ui.form {
  margin-top: 3em;
}
.ui.form .field {
  margin-top: 2em;
}
.regmain {
  padding-top: 3em;
  padding-bottom: 3em;
}
.ui.header.account-header {
  color: black;
  font-family: HK Grotesk Light, sans-serif;
  font-weight: 0;
  font-size: 2.5em;
  letter-spacing: 2px;
  font-weight: 100;
  text-align: center;
}
.ui.form input:not([type]),
.ui.form input[type="date"],
.ui.form input[type="datetime-local"],
.ui.form input[type="email"],
.ui.form input[type="file"],
.ui.form input[type="number"],
.ui.form input[type="password"],
.ui.form input[type="search"],
.ui.form input[type="tel"],
.ui.form input[type="text"],
.ui.form input[type="time"],
.ui.form input[type="url"] {
  border: 0;
  outline: none;
  background: transparent;
  border-bottom: 1px solid black;
  border-radius: 0;
}

.ui.form .field > label {
  font-weight: 100;
  font-size: 1em;
  text-align: left;
  margin-left: 1em;
  color: black;
  font-family: HK Grotesk Light, sans-serif;
}
.register-button-div {
  text-align: center;
  margin-top: 3em;
}
.ui.button.register {
  margin-top: 1em;
  white-space: nowrap;
  z-index: 1;
  font-size: 0.875em;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: #fff;
  width: auto;
  padding: 15px 40px;
  border: none;
  background: #3366cc;
  border-radius: 30px;
  transition: 0.5s;
  transition: all 0.3s ease;
  transform: scale(1);
  font-family: 'Titillium Web';
}
.ui.button.register:hover {
  transform: scale(1.1) perspective(1px);
}

.column {
  float: left;
  width: 33.33%;
  padding: 5px;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}
