.videomain
{
    padding-top: 3em;
    padding-bottom: 3em;
}
.ui.header.video-header
{
    color: #EF6286;
    font-family: 'Titillium Web';
    font-size: 2.1em;
    margin: 0;
    text-transform: uppercase;
    text-align: center;
}

.year-div
{
    padding-top: 2em;
    padding-bottom: 2em;
}
.ui.header.year-header
{
    color: #EF6286;
    font-family: 'Titillium Web';
    font-size: 1.6m;
    margin: 0;
    text-transform: uppercase;
}
.ui.grid.video-grid
{
    text-align: center;
}

.ui.grid.video-grid.column
{
    width: 50%;
    margin-left: 25%;
}