.class-image
{
    vertical-align: middle;
    margin-top: 2em;
}
.college-coloumn
{
    padding-left: 10em !important;
}
.imagediv
{
    margin-top: 2em;
    margin-left: auto;
    margin-right: auto;
    width:50%;
}