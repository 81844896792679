#sum0 {
  height: 100vh;
  background-image: url("../summer.png");
  background-size: cover;
}
@media only screen and (max-width: 700px) {
  #sum0 {
    height: auto;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1200px) {
  #sum0 {
    height: auto;
  }
}
#sum0 {
  padding-top: 3em;
  padding-bottom: 3em;
}
#sum1 {
  padding-top: 3em;
  padding-bottom: 3em;
}
.summermain-header-div {
  padding-top: 30vh;
}
.ui.header.summermain-header {
  color: white;
  font-size: 2.5em;
  font-family: 'Titillium Web';
  margin: 0;
  text-align: left;
}
@media only screen and (max-width: 700px) {
  .summermain-header-div {
    padding-top: 0vh;
  }
  .ui.header.summermain-header {
    font-size: 2em;
    text-align: center;
  }
}
.ui.grid.button-grid
{
  margin-top: 2em;
}
@media only screen and (max-width: 700px) {
.ui.grid.button-grid
{
  text-align: center;
}
}
.ui.button.summermain-button {
  white-space: nowrap;
  z-index: 1;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  width: auto;
  padding: 15px 40px;
  border: none;
  background: teal;
  border-radius: 15px;
  transition: 0.5s;
  font-family: 'Titillium Web';
  transition: all 0.3s ease;
  transform: scale(1);
}
.ui.button.summermain-button:hover {
  transform: scale(1.1) perspective(1px);
}

.ui.header.offer-header {
  color: #ef6286;
  font-size: 2.1em;
  font-family: 'Titillium Web';
  font-weight: 500;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.image-grid {
  text-align: center;
  padding-top: 3em;
}
.image-summer-holder {
  margin-bottom: 3em;
}
.image-num {
  background: #05a6b3;
  color: #fff;
  padding: 9px 15px;
  margin-right: 5%;
}
.ui.header.image-content {
  color: #05a6b3;
  padding: 10px 0px;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Titillium Web';
  background: rgba(34, 34, 34, 0.08);
  text-align: left;
  margin: 0;
}
