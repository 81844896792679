#home1 {
  height: auto;
  background-image: url("../HomeImage/banner.png");
  background-size: cover;
}
.homepage {
  padding-top: 30vh;
  
}
.message-body{
  padding-bottom: 3em;
}
@media only screen and (max-width: 700px) {
  #home1,
  #home1-a,
  #home1-a0, #home1-a1 {
    height: auto;
  }
  .homepage {
    padding-top: 5em;
    padding-bottom: 5em;
    text-align: left;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1200px) {
  #home1 {
    height: auto;
  }
  .homepage {
    padding-top: 15em;
    padding-bottom: 5em;
    text-align: left;
  }
}

.home-grid {
  position: relative;
}
.ui.image.logo {
  margin-top: -5em;
}
@media only screen and (max-width: 700px) {
  .ui.grid > .row > .column > img,
  .ui.grid > .row > img {
    max-width: 80%;
  }
  .ui.image.logo {
    margin: 0em auto;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1200px) {
  .ui.grid > .row > .column > img,
  .ui.grid > .row > img {
    max-width: 80%;
  }
  .ui.image.logo {
    margin: -3em auto;
  }
}
.ui.header.hook {
  font-family: 'Titillium Web';
  font-size: 2.5em;
  text-align: left;
  margin-bottom: 0.5em;
  font-weight: 800;
  color: white;
}
.ui.header.name {
  font-family: 'Titillium Web';
  text-align: center;
  font-size: 3em;
  color: white;
  text-align: left;
}
.reg-button-contain {
  margin-top: 2em;
  text-align: left;
}
.ui.button.reg-button {
  white-space: nowrap;
  z-index: 1;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  width: auto;
  padding: 15px 40px;
  border: none;
  background: teal;
  border-radius: 15px;
  transition: 0.5s;
  font-family: 'Titillium Web';
  transition: all 0.3s ease;
  transform: scale(1);
}
.ui.button.reg-button:hover {
  transform: scale(1.1) perspective(1px);
}
@media only screen and (max-width: 700px) {
  .ui.header.hook {
    font-size: 2em;
  }
  .ui.header.name {
    font-size: 2em;
  }
  .ui.header.date {
    font-size: 2em;
  }
}
@media only screen and (min-width: 701px) and (max-width: 1200px) {
  .ui.header.hook {
    font-size: 2.5em;
  }
  .ui.header.name {
    font-size: 3em;
  }
  .ui.header.date {
    font-size: 2.5em;
  }
}

.ui.card > .content,
.ui.cards > .card > .content {
  padding: 0;
  border: none;
}

/* .link-home4
{
  color:white;
} */