#sum3
{
    background-color: white;
}
.sum30
{
    padding-top: 3em;
    padding-bottom: 3em;
}
.sum3-head-div
{
    text-align: center;
    margin-bottom: 2em;
}
.ui.header.sum3-head
{
    color: #EF6286;
    font-size: 2.1em;
    font-family: 'Titillium Web';
    margin: 0;
    text-transform: uppercase;
    text-align: center;
 
}
.ui.button.syllabus-button {
    white-space: nowrap;
    z-index: 1;
    font-size: 1em;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #05a6b3;
    width: auto;
    padding: 10px 20px;
    border: 2px solid #05a6b3;
    border-radius: 15px;
    font-family: 'Titillium Web';
    transition: all 0.3s ease;
    transform: scale(1);
    background-color: transparent;
  }
  .ui.button.syllabus-button:hover {
    transform: scale(1.1) perspective(1px);
  }
