.fixed-navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  position: relative;
}
.navbark.navbar.navbar-expand-lg.navbar-light.sticky-top
{
    background-color: white;
    z-index:1000000000;
}
.ui.image.logo {
  transition: all 0.5s ease;
    transform: scale(1);
    margin-top:auto;
    margin-bottom: auto;
    max-width: 90%;
}
@media only screen and (max-width: 700px)
{
  .ui.image.logo 
  {
    max-width: 100%;
  }
}

.ui.image.logo:hover{
    
    transform: scale(1.1) perspective(1px);
}


.navbar-light .navbar-nav .nav-link {
  margin-left: 40px;
  color: #3366CC;
  border-radius: 0px;
  font-family: 'Titillium Web';
  font-weight: 100;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
  margin-bottom:auto;
  font-size: 20px;
  border-bottom: 3px solid transparent;
}

.navbar-light .navbar-nav .nav-link:hover{
    transition: 1s;
    border-bottom: 3px solid #3366CC;
}
.navbar-light .navbar-nav .nav-link:active{
    border-bottom: 3px solid #3366CC;
}