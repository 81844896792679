#sum2
{
    background-color: rgba(34, 34, 34, 0.09);
}
.sum20
{
    padding-top: 3em;
    padding-bottom: 3em;
}
.sum2-head-div
{
    text-align: center;
    margin-bottom: 2em;
}
.ui.header.sum2-head
{
    color: #EF6286;
    font-size: 2.1em;
    font-family: 'Titillium Web';
    margin: 0;
    text-transform: uppercase;
    text-align: center;
 
}

.ui.header.question-header
{
    color: #EF6286;
    font-family: 'Titillium Web';
    font-size: 1.3em;
    margin: 0;
    text-transform: uppercase;
}