#faq
{
    background-color: rgba(34, 34, 34, 0.09);
    height:auto;
}
.faqmain
{
    padding-top: 3em;
    padding-bottom:3em;
}

.ui.header.faq-header
{
    font-size:2.1em;
    color: #EF6286;
    font-family: 'Titillium Web';
    white-space: normal;
    letter-spacing: 1px;
    text-align: center;
}
.ui.header.faq-question-header
{
    font-size:1.5em;
    color: #EF6286;
    font-family: 'Titillium Web';
    white-space: normal;
    letter-spacing: 1px;
}
.ui.header.answer-header
{
    font: 18px;
    color:#5a5a5a;;
    font-family: 'Titillium Web';
    white-space: normal;
    text-align: left;
}
.question
{
    margin-top: 3em;
}
.question-content
{
    margin-top: 2em;
}