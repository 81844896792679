#home2
{
    background-color:rgba(34, 34, 34, 0.09);
    height:auto;
}
.home2main
{
    padding-top: 3em;
    padding-bottom: 3em;

}
.grid-space
{
    text-align: center;
    margin-top: 1em;
}



.ui.header.grid-header
{
    font-size:1.5em;
    text-transform: uppercase;
    color:#EF6286;
    font-weight: 800;
    font-family: 'Titillium Web';
    letter-spacing: 1px;
    white-space: normal;
    text-align: center;
}
.ui.header.grid-des
{
    font-size:20px;
    color:#5a5a5a;;
    font-family: 'Titillium Web';
    white-space: normal;
    text-align: center;
}
