.ui.header.class-name
{
    font-size:1.5em;
    text-transform: uppercase;
    color:#5a5a5a;;
    font-weight: 800;
    font-family: 'Titillium Web';
    letter-spacing: 1px;
    white-space: normal;
    text-align: left;
}
.ui.header.class-description
{
    font-size:18px;
    color:#5a5a5a;;
    font-family: 'Titillium Web';
    white-space: normal;
    text-align: left;
    
}
.course-div-b
{
    margin-top: 1em;
}
.course-div-c
{
    margin-top: 1em;
}
.border
{
    margin-top: 1em;
    margin-bottom: 1em;
    border:1px solid rgba(0, 0, 0, 0.1);
}